/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FuseNavigationItem } from "@fuse/components/navigation";
import { environment } from "environments/environment";

export const navigation: (Omit<FuseNavigationItem, "link"> & { link: ((token: string) => string) | string })[] = [
    {
        id: "painel",
        title: "Painel",
        link: (token) => {
            return `${environment.vars.next}/dashboard`;
        },
        externalLink: true,
        type: "basic",
        hidden: () => {
            const permissions = JSON.parse(localStorage.getItem("permissions")) as Array<string>;

            const read = permissions.some((permission: string) => permission === "painel:read");

            return !read;
        },
        permissions: ["painel:read"],
    },
    {
        isStarter: true,
        id: "Clientes",
        title: "Clientes",
        type: "basic",
        link: (token) => {
            return `${environment.vars.next}/clients`;
        },
        externalLink: true,
        hidden: () => {
            const permissions = JSON.parse(localStorage.getItem("permissions")) as Array<string>;

            const read = permissions.some((permission: string) => permission === "clientes:read");

            return !read;
        },
        permissions: ["clientes:read"],
    },
    {
        id: "Sacados",
        title: "Sacados",
        type: "basic",
        link: (token) => {
            return `${environment.vars.next}/payers`;
        },
        externalLink: true,
        hidden: () => {
            const permissions = JSON.parse(localStorage.getItem("permissions")) as Array<string>;

            const read = permissions.some((permission: string) => permission === "sacados:read");

            return !read;
        },
        permissions: ["sacados:read"],
    },
    {
        isStarter: true,
        id: "operacoes",
        title: "Operações",
        externalLink: true,
        type: "basic",
        link: (token) => {
            return `${environment.vars.next}/operations`;
        },
        hidden: () => {
            const permissions = JSON.parse(localStorage.getItem("permissions")) as Array<string>;

            const read = permissions.some((permission: string) => permission === "operacoes:read");

            return !read;
        },
        permissions: ["operacoes:read"],
    },
    {
        id: "ccb",
        title: "CCB",
        link: (token) => {
            return `${environment.vars.next}/ccb/simulate/create`;
        },
        externalLink: true,
        type: "basic",
        hidden: () => {
            const permissions = JSON.parse(localStorage.getItem("permissions")) as Array<string>;

            const read = permissions.some((permission: string) => permission === "ccb:create");

            return !read;
        },
        permissions: ["ccb:create"],
    },
    {
        id: "investimentos",
        title: "Investimentos",
        link: (token) => {
            return `${environment.vars.next}/investments`;
        },
        externalLink: true,
        type: "basic",
        badge: {
            classes: "ml-1 px-2 bg-green-600 text-white rounded text-xs",
            title: "Novo",
        },
        hidden: () => {
            const permissions = JSON.parse(localStorage.getItem("permissions")) as Array<string>;

            const read = permissions.some((permission: string) => permission === "investimentos:read");

            return !read;
        },
        permissions: ["investimentos:read"],
    },
    {
        id: "titulos",
        title: "Títulos Operáveis",
        type: "basic",
        link: "/titulos",
        hidden: () => {
            const permissions = JSON.parse(localStorage.getItem("permissions")) as Array<string>;

            const read = permissions.some((permission: string) => permission === "titulos_operaveis:read");

            return !read;
        },
        permissions: ["titulos_operaveis:read"],
    },
    {
        id: "calculame",
        title: "Calcula-me",
        type: "basic",
        link: "/calcula-me",
        hidden: () => {
            const permissions = JSON.parse(localStorage.getItem("permissions")) as Array<string>;

            const read = permissions.some((permission: string) => permission === "calcula_me:create");

            return !read;
        },
        permissions: ["calcula_me:create"],
    },
    {
        id: "relatorios",
        title: "Relatórios",
        type: "basic",
        link: (token) => {
            return `${environment.vars.next}/reports`;
        },
        hidden: () => {
            const permissions = JSON.parse(localStorage.getItem("permissions")) as Array<string>;

            const read = permissions.some((permission: string) => permission === "relatorios:read");

            return !read;
        },
        externalLink: true,
        active: false,
        permissions: ["relatorios:read"],
    },
    {
        id: "usuarios",
        title: "Usuários",
        type: "basic",
        link: "/usuarios",
        hidden: () => true,
        permissions: ["usuarios:create"],
    },
];
