<!-- Navigation -->
<ng-container *ngIf="isScreenMD || isScreenSM">
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="data.navigation.default"
        [opened]="false"
    >
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <img class="w-24" src="assets/images/logo/bankme-logo-blue.svg" alt="Logo image" />
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0">
    <!-- Header -->
    <div class="relative flex justify-center w-full z-49">
        <div class="w-full pt-2 px-5 sm:pt-5 sm:px-9 md:px-11 lg:px-12 xl:px-16">
            <!-- Top bar -->
            <div class="relative flex justify-between">
                <!-- Logo -->
                <div class="flex items-center mx-2 gap-2">
                    <img class="h-7" src="assets/images/logo/bankme-logo-blue.svg" alt="Logo image" />
                    <p
                        *ngIf="environment === 'demo'"
                        class="bg-blue-400 font-bold text-white py-[2px] px-2 rounded-xl text-center xl:whitespace-nowrap"
                    >
                        Ambiente de Demonstração
                    </p>
                </div>

                <!-- Desktop Navigation bar -->
                <ng-container *ngIf="!isScreenMD && !isScreenSM; else mobileNavigationToggle">
                    <fuse-horizontal-navigation
                        class="w-full"
                        [name]="'mainNavigation'"
                        [navigation]="data.navigation.horizontal"
                    ></fuse-horizontal-navigation>
                </ng-container>

                <!-- Navigation toggle button -->
                <ng-template #mobileNavigationToggle>
                    <button mat-icon-button (click)="toggleNavigation('mainNavigation')" class="ml-auto">
                        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                    </button>
                </ng-template>

                <!-- Components -->
                <div *ngIf="!isScreenMD && !isScreenSM" class="flex flex-row items-center justify-center gap-8 ml-auto">
                    <app-platform-updates [hasText]="false"></app-platform-updates>
                    <user-menu></user-menu>
                </div>
            </div>
            <div class="h-px w-full bg-gray-300 mt-4"></div>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full h-full bg-blueGray">
        <div class="w-full flex flex-col flex-auto bg-offWhite rounded-b-xl">
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>
</div>
