import moment, { Moment } from "moment";

const holidays = [
    "2023-01-01",
    "2023-02-20",
    "2023-02-21",
    "2023-04-07",
    "2023-04-09",
    "2023-04-21",
    "2023-05-01",
    "2023-06-08",
    "2023-09-07",
    "2023-10-12",
    "2023-11-02",
    "2023-11-15",
    "2023-12-25",
    "2023-12-31",
    "2024-01-01",
    "2024-02-12",
    "2024-02-13",
    "2024-03-29",
    "2024-03-31",
    "2024-04-21",
    "2024-05-01",
    "2024-05-30",
    "2024-09-07",
    "2024-10-12",
    "2024-11-02",
    "2024-11-15",
    "2024-12-25",
    "2024-12-31",
    "2025-01-01",
    "2025-03-03",
    "2025-03-04",
    "2025-04-18",
    "2025-04-20",
    "2025-04-21",
    "2025-05-01",
    "2025-06-19",
    "2025-09-07",
    "2025-10-12",
    "2025-11-02",
    "2025-11-15",
    "2025-12-25",
    "2025-12-31",
    "2026-01-01",
    "2026-02-16",
    "2026-02-17",
    "2026-04-03",
    "2026-04-05",
    "2026-04-21",
    "2026-05-01",
    "2026-06-04",
    "2026-09-07",
    "2026-10-12",
    "2026-11-02",
    "2026-11-15",
    "2026-12-25",
    "2026-12-31",
    "2027-01-01",
    "2027-02-08",
    "2027-02-09",
    "2027-03-26",
    "2027-03-28",
    "2027-04-21",
    "2027-05-01",
    "2027-05-27",
    "2027-09-07",
    "2027-10-12",
    "2027-11-02",
    "2027-11-15",
    "2027-12-25",
    "2027-12-31",
    "2028-01-01",
    "2028-02-28",
    "2028-02-29",
    "2028-04-14",
    "2028-04-16",
    "2028-04-21",
    "2028-05-01",
    "2028-06-15",
    "2028-09-07",
    "2028-10-12",
    "2028-11-02",
    "2028-11-15",
    "2028-12-25",
    "2028-12-31",
    "2029-01-01",
    "2029-02-12",
    "2029-02-13",
    "2029-03-30",
    "2029-04-01",
    "2029-04-21",
    "2029-05-01",
    "2029-05-31",
    "2029-09-07",
    "2029-10-12",
    "2029-11-02",
    "2029-11-15",
    "2029-12-25",
    "2029-12-31",
    "2030-01-01",
    "2030-03-04",
    "2030-03-05",
    "2030-04-19",
    "2030-04-21",
    "2030-05-01",
    "2030-06-20",
    "2030-09-07",
    "2030-10-12",
    "2030-11-02",
    "2030-11-15",
    "2030-12-25",
    "2030-12-31",
    "2031-01-01",
    "2031-02-24",
    "2031-02-25",
    "2031-04-11",
    "2031-04-13",
    "2031-04-21",
    "2031-05-01",
    "2031-06-12",
    "2031-09-07",
    "2031-10-12",
    "2031-11-02",
    "2031-11-15",
    "2031-12-25",
    "2031-12-31",
    "2032-01-01",
    "2032-02-09",
    "2032-02-10",
    "2032-03-26",
    "2032-03-28",
    "2032-04-21",
    "2032-05-01",
    "2032-05-27",
    "2032-09-07",
    "2032-10-12",
    "2032-11-02",
    "2032-11-15",
    "2032-12-25",
    "2032-12-31",
    "2033-01-01",
    "2033-02-28",
    "2033-03-01",
    "2033-04-15",
    "2033-04-17",
    "2033-04-21",
    "2033-05-01",
    "2033-06-16",
    "2033-09-07",
    "2033-10-12",
    "2033-11-02",
    "2033-11-15",
    "2033-12-25",
    "2033-12-31",
    "2034-01-01",
    "2034-02-20",
    "2034-02-21",
    "2034-04-07",
    "2034-04-09",
    "2034-04-21",
    "2034-05-01",
    "2034-06-08",
    "2034-09-07",
    "2034-10-12",
    "2034-11-02",
    "2034-11-15",
    "2034-12-25",
    "2034-12-31",
    "2035-01-01",
    "2035-02-05",
    "2035-02-06",
    "2035-03-23",
    "2035-03-25",
    "2035-04-21",
    "2035-05-01",
    "2035-05-24",
    "2035-09-07",
    "2035-10-12",
    "2035-11-02",
    "2035-11-15",
    "2035-12-25",
    "2035-12-31",
    "2036-01-01",
    "2036-02-25",
    "2036-02-26",
    "2036-04-11",
    "2036-04-13",
    "2036-04-21",
    "2036-05-01",
    "2036-06-12",
    "2036-09-07",
    "2036-10-12",
    "2036-11-02",
    "2036-11-15",
    "2036-12-25",
    "2037-01-01",
    "2037-02-16",
    "2037-02-17",
    "2037-04-03",
    "2037-04-05",
    "2037-04-21",
    "2037-05-01",
    "2037-06-04",
    "2037-09-07",
    "2037-10-12",
    "2037-11-02",
    "2037-11-15",
    "2037-12-25",
    "2038-01-01",
    "2038-03-08",
    "2038-03-09",
    "2038-04-21",
    "2038-04-23",
    "2038-04-25",
    "2038-05-01",
    "2038-06-24",
    "2038-09-07",
    "2038-10-12",
    "2038-11-02",
    "2038-11-15",
    "2038-12-25",
    "2039-01-01",
    "2039-02-21",
    "2039-02-22",
    "2039-04-08",
    "2039-04-10",
    "2039-04-21",
    "2039-05-01",
    "2039-06-09",
    "2039-09-07",
    "2039-10-12",
    "2039-11-02",
    "2039-11-15",
    "2039-12-25",
    "2040-01-01",
    "2040-02-13",
    "2040-02-14",
    "2040-03-30",
    "2040-04-01",
    "2040-04-21",
    "2040-05-01",
    "2040-05-31",
    "2040-09-07",
    "2040-10-12",
    "2040-11-02",
    "2040-11-15",
    "2040-12-25",
];
const isWeekend = (date: Moment) => {
    const day = date.day();
    return day === 0 || day === 6;
};

const isHoliday = (date: Moment) => {
    const dateStr = date.format("YYYY-MM-DD");
    return holidays.includes(dateStr);
};

export const getNextBusinessDay = (date: Date) => {
    const nextDate = moment(date).add(3, "hours");

    if (isWeekend(nextDate) || isHoliday(nextDate)) {
        return getNextBusinessDay(nextDate.add(1, "day").toDate());
    }

    return nextDate.toDate();
};