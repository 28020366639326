import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { IDataAnticipation, IDataLoan } from "./types";
import { IResultCalc } from "./antecipacao/types";
import { Observable } from "rxjs";
import { debounceTime, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class CalculoService {
    constructor(private _apiService: ApiService) {}

    calculoEmprestimo(dataLoan: IDataLoan) {
        return this._apiService.post("crm", "/calcula-me/emprestimo", dataLoan);
    }

    calculoAntecipacao(calculatorAntecipacao) {
        return this._apiService.post("crm", "/calcula-me/anticipation", calculatorAntecipacao);
    }

    calculateFunding(operationData: IDataAnticipation): Observable<IResultCalc> {
        return this._apiService.post("oracle", "/calcula-me/funding", operationData).pipe(
            debounceTime(500),
            map((response) => {
                return response;
            }),
        );
    }
}
