/* eslint-disable @typescript-eslint/naming-convention */
import { Route } from "@angular/router";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";
import { InitialDataResolver } from "app/app.resolvers";
import { AuthService } from "./core/auth/auth.service";

export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: "", pathMatch: "full", redirectTo: "dashboard" },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: "signed-in-redirect", pathMatch: "full", redirectTo: "dashboard" },

    {
        path: "redirect",
        loadChildren: () => import("app/modules/auth/redirect/redirect.module").then((m) => m.AuthRedirectModule),
    },

    // Auth routes for guests
    {
        path: "",
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: "empty",
        },
        children: [
            {
                path: "forgot-password",
                loadChildren: () =>
                    import("app/modules/auth/forgot-password/forgot-password.module").then(
                        (m) => m.AuthForgotPasswordModule,
                    ),
            },
            {
                path: "redefine-password",
                loadChildren: () =>
                    import("app/modules/auth/redefine-password/redefine-password.module").then(
                        (m) => m.RedefinePasswordModule,
                    ),
            },
            {
                path: "sign-in",
                loadChildren: () => import("app/modules/auth/sign-in/sign-in.module").then((m) => m.AuthSignInModule),
            },
            {
                path: "contatos/unsubscribe",
                loadChildren: () =>
                    import("app/modules/auth/unsub-email/unsub-email.module").then((m) => m.UnsubEmailModule),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: "",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: "empty",
        },
        children: [
            {
                path: "sign-out",
                loadChildren: () =>
                    import("app/modules/auth/sign-out/sign-out.module").then((m) => m.AuthSignOutModule),
            },
        ],
    },

    // Admin routes & permite btn menu acessar a rota
    {
        path: "",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: "dashboard",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        DASHBOARD: ["READ"],
                    },
                },
                loadChildren: () =>
                    import("app/modules/admin/v1/dashboard/dashboard.module").then((m) => m.DashboardModule),
            },
            {
                path: "clientes",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        CEDENTE: ["READ"],
                    },
                },
                loadChildren: () => import("app/modules/admin/v1/cedente/cedente.module").then((m) => m.ClientesModule),
            },
            {
                path: "operacoes",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        OPERACAO: ["READ"],
                    },
                },
                loadChildren: () =>
                    import("app/modules/admin/v1/operacoes/operacoes.module").then((m) => m.OperacoesModule),
            },
            {
                path: "operation",
                canActivate: [AuthService],
                data: { permissoes: { OPERACAO: ["READ"] } },
                loadChildren: () =>
                    import("app/modules/admin/v2/operation/create/create-operation.module").then(
                        (m) => m.CreateOperationModule,
                    ),
            },
            {
                path: "assignor",
                canActivate: [AuthService],
                data: { permissoes: { CEDENTE: ["READ"] } },
                loadChildren: () =>
                    import("app/modules/admin/v2/assignor/create/create-assignor.module").then(
                        (m) => m.CreateAssignorModule,
                    ),
            },
            {
                path: "titulos",
                canActivate: [AuthService],
                data: { permissoes: { NFE: ["READ"] } },
                loadChildren: () => import("app/modules/admin/v1/titulos/titulos.module").then((m) => m.TitulosModule),
            },
            {
                path: "arquivos",
                canActivate: [AuthService],
                data: { permissoes: { ARQUIVO: ["READ"] } },
                loadChildren: () =>
                    import("app/modules/admin/v1/titulos/file-manager/file-manager.module").then(
                        (m) => m.FileManagerModule,
                    ),
            },
            {
                path: "usuarios",
                canActivate: [AuthService],
                data: { permissoes: { USUARIO: ["READ"] } },
                loadChildren: () =>
                    import("app/modules/admin/v1/usuarios/usuario.module").then((m) => m.UsuariosModule),
            },
            {
                path: "calcula-me",
                canActivate: [AuthService],
                data: { permissoes: { USUARIO: ["READ"] } },
                loadChildren: () =>
                    import("app/modules/admin/v1/calcula-me/calcula-me.module").then((m) => m.CalculaMeModule),
            },
            {
                path: "perfil",
                canActivate: [AuthService],
                data: { permissoes: { USUARIO: ["READ"] } },
                loadChildren: () =>
                    import("app/modules/admin/v1/usuarios/perfil/perfil.module").then((m) => m.PerfilModule),
            },
            {
                path: "conta",
                loadChildren: () =>
                    import("app/modules/admin/v1/myAccount/my-account.module").then((m) => m.MyAccountModule),
            },
            {
                path: "relatorios",
                canActivate: [AuthService],
                data: { permissoes: { DASHBOARD: ["READ"] } },
                loadChildren: () => import("app/modules/admin/v1/reports/reports.module").then((m) => m.ReportsModule),
            },
            {
                path: "extrato",
                loadChildren: () => import("app/modules/admin/v1/extrato/extrato.module").then((m) => m.ExtratoModule),
            },
            {
                path: "xml-modal",
                loadChildren: () =>
                    import("app/components/v1/xml-modal/envio-notas-modal.module").then(
                        (m) => m.EnviarNotasModalModule,
                    ),
            },
            {
                path: "payables-from-database-modal",
                loadChildren: () =>
                    import("app/components/v1/payables-from-database-modal/payables-from-databse-modal.module").then(
                        (m) => m.PayablesFromDatabaseModalModule,
                    ),
            },
            {
                path: "create-payables-modal",
                loadChildren: () =>
                    import("app/components/v1/create-payables-modal/create-payables-modal.module").then(
                        (m) => m.CreatePayablesModalModule,
                    ),
            },
            {
                path: "create-sacado-modal",
                loadChildren: () =>
                    import("app/components/v1/create-sacado-modal/create-sacado-modal.module").then(
                        (m) => m.CreatePayablesModalModule,
                    ),
            },
            {
                path: "payers",
                loadChildren: () => import("app/modules/admin/v1/payer/payer.module").then((m) => m.PayersModule),
            },
        ],
    },

    {
        path: "**",
        loadChildren: () => import("app/modules/error/error-404/error-404.module").then((m) => m.Error404Module),
    },
];
