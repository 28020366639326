<div class="w-full h-full">
    <!-- STEPPER -->
    <mat-stepper class="w-full h-full pb-32 md:px-6" #stepper (selectionChange)="onStepChange()">
        <!-- Informações Básicas -->
        <mat-step>
            <div class="w-full grid md:grid-cols-2 gap-20">
                <!-- Basic info form -->
                <form [formGroup]="basicInfoForm" class="custom-pad">
                    <h1 class="text-3xl font-bold py-6">Informações Básicas</h1>

                    <mat-form-field class="w-full" floatLabel="always">
                        <mat-label>Cedente</mat-label>
                        <input
                            #assignor
                            class="w-full"
                            matInput
                            formControlName="assignor"
                            type="text"
                            placeholder="Cedente"
                            [matAutocomplete]="autoAssignor"
                            required
                        />
                        <mat-icon matSuffix svgIcon="heroicons_outline:user"></mat-icon>
                        <mat-autocomplete
                            autoActiveFirstOption
                            #autoAssignor="matAutocomplete"
                            [displayWith]="displayCorporateName"
                        >
                            <mat-option *ngFor="let assignor of filteredAssignors$ | async" [value]="assignor">
                                {{ assignor && assignor.razaoSocial }} - {{ assignor.cnpj }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <!-- Tipo da Operação -->
                    <div class="grid md:grid-cols-2 gap-x-4">
                        <mat-form-field appearance="fill" floatLabel="always">
                            <mat-label>Tipo da Operação</mat-label>
                            <mat-select
                                #filterType
                                placeholder="Tipo de Operação"
                                formControlName="typeOperation"
                                required
                            >
                                <mat-option *ngFor="let type of operationTypes" [value]="type.id">
                                    {{ treatCase(type.attributes.description) }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- Modalidade da Operação -->
                        <mat-form-field appearance="fill" floatLabel="always">
                            <mat-label>Modalidade da Operação</mat-label>
                            <mat-select
                                #filterType
                                placeholder="Modalidade da Operação"
                                formControlName="mode"
                                required
                                (selectionChange)="updateOperationMode($event)"
                            >
                                <mat-option *ngFor="let mode of filteredOperationModes$ | async" [value]="mode.id">
                                    {{ treatCase(mode.descricao) }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </form>
                <OperationSummary
                    [risk]=""
                    [minibank]="minibankName"
                    [assignor]="assignorName"
                    [expanded]="isSummaryExpanded"
                    [operationData]="operationData"
                    (expandHideEmitter)="isSummaryExpanded = !isSummaryExpanded"
                ></OperationSummary>
            </div>
        </mat-step>

        <!-- Selecionar tipo de recebíveis -->
        <mat-step>
            <div class="w-full grid md:grid-cols-2 gap-20">
                <div class="custom-pad">
                    <h1 class="text-3xl font-bold py-6">Adicionar recebíveis operáveis</h1>
                    <h2 class="text-xl font-bold pb-6">Qual tipo de recebível será adicionado?</h2>

                    <form class="w-full" [formGroup]="feesForm">
                        <mat-form-field appearance="fill" class="w-full" floatLabel="always">
                            <mat-label>Tipo de recebível</mat-label>
                            <mat-select
                                #filterType
                                placeholder="Selecione..."
                                formControlName="payableType"
                                required
                                (selectionChange)="changePayableType($event)"
                                [disabled]="disablePayableType"
                            >
                                <mat-option *ngFor="let type of payableTypeList" [value]="type.value">
                                    {{ type.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>

                    <div class="mt-5 flex flex-col gap-3" *ngIf="selectedPayableType">
                        <form
                            class="w-full"
                            [formGroup]="emitPaymentSlip"
                            [ngClass]="{
                                hidden:
                                    (!showEmit || bankType !== 'STANDARD') &&
                                    feesForm.controls.payableType.value !== 'CHEQUE'
                            }"
                        >
                            <mat-label>
                                {{
                                    feesForm.controls.payableType.value === "CHEQUE"
                                        ? "Há custódia de cheque?"
                                        : "Gostaria de emitir boletos nesta operação?"
                                }}
                            </mat-label>

                            <mat-radio-group
                                class="flex gap-2 mx-2"
                                [disabled]="bankType !== 'STANDARD' && feesForm.controls.payableType.value !== 'CHEQUE'"
                                formControlName="hasEmit"
                            >
                                <mat-radio-button value="true" checked="true">Sim</mat-radio-button>
                                <mat-radio-button value="false">Não</mat-radio-button>
                            </mat-radio-group>
                        </form>
                    </div>
                </div>
                <OperationSummary
                    [risk]=""
                    [operationData]="operationData"
                    [minibank]="minibankName"
                    [assignor]="assignorName"
                    [expanded]="isSummaryExpanded"
                    (expandHideEmitter)="isSummaryExpanded = !isSummaryExpanded"
                ></OperationSummary>
            </div>
        </mat-step>

        <!-- Adicionar recebíveis operáveis -->
        <mat-step>
            <div class="w-full grid md:grid-cols-2 gap-20">
                <div class="custom-pad">
                    <h1 class="text-3xl font-bold pt-6 pb-2">Adicionar recebíveis operáveis</h1>
                    <p class="text-neutral-800">
                        Adicione os recebíveis para visualizar os valores da operação. Para adicioná-los, basta
                        selecionar uma das opções no botão abaixo.
                    </p>
                    <!-- Buttons de adc recebivel -->
                    <button
                        mat-button
                        [matMenuTriggerFor]="menu"
                        class="border border-primary-500 hover:bg-offWhite text-primary-500 font-bold py-2 px-4 rounded-full inline-flex items-center my-5"
                    >
                        <mat-icon svgIcon="heroicons_solid:plus" class="icon-size-5 mr-2 text-primary-500"></mat-icon>
                        Adicionar recebíveis
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openChooseUploadFileModal()">Importar recebíveis</button>
                        <button mat-menu-item (click)="openSearchPayablesInDataBaseModal()">
                            Buscar recebíveis na base
                        </button>
                        <button mat-menu-item (click)="openModalCreatePayables()">Criar recebível</button>
                    </mat-menu>

                    <div *ngIf="inconsistencies?.errors">
                        <hr />
                        <h2 class="text-xl font-bold pb-6">Confira abaixo os arquivos com inconsistências</h2>
                        <InconsistenciesCards [inconsistencies]="inconsistencies"></InconsistenciesCards>
                    </div>

                    <div
                        class="text-white p-4 rounded-2xl cursor-pointer mb-10"
                        style="background-color: #2c6dde"
                        (click)="openRepurchasePayables()"
                        *ngIf="showUnoperatedPayables"
                    >
                        <p class="font-semibold gap-2">
                            <mat-icon
                                [svgIcon]="'heroicons_outline:tag'"
                                color="white"
                                class="icon-size-3 text-white"
                            ></mat-icon>
                            {{
                                selectedUnoperatedPayables.length > 0
                                    ? selectedUnoperatedPayables.length + " título(s) selecionado(s) para Recompra"
                                    : "Identificamos que existem títulos disponíveis para Recompra."
                            }}
                        </p>
                        <p class="font-bold">Clique para selecionar títulos para recomprar</p>
                    </div>

                    <RepurchasePayables
                        *ngIf="isShow"
                        (closeEmitter)="isShow = false"
                        [selectionUnoperatedPayables]="selectionUnoperatedPayables"
                        (selectEmitter)="selectedRepurchasedPayables($event)"
                        [assignorId]="basicInfoForm.get('assignor').value.id"
                    ></RepurchasePayables>

                    <!-- Taxas -->
                    <div class="custom-pad">
                        <h1 class="text-3xl font-bold py-0">Taxas e tarifas</h1>
                        <form [formGroup]="feesForm">
                            <div class="grid md:grid-cols-3 gap-x-2">
                                <!-- Taxa -->
                                <mat-form-field appearance="fill">
                                    <mat-label>Taxa <span class="text-gray-400">(Obrigatório)</span></mat-label>
                                    <input
                                        #taxa
                                        formControlName="feePerMonth"
                                        matInput
                                        inputmode="numeric"
                                        currencyMask
                                        [options]="{
                                            prefix: '',
                                            suffix: ' %',
                                            thousands: '.',
                                            decimal: ',',
                                            align: 'left'
                                        }"
                                        currencyFormatter
                                    />
                                    <mat-icon matSuffix svgIcon="heroicons_outline:receipt-tax"></mat-icon>
                                    <mat-hint *ngIf="minibankPredefinedFees?.minimalTax">
                                        * Taxa mínima {{ minibankPredefinedFees.minimalTax }}%
                                    </mat-hint>
                                </mat-form-field>

                                <!-- TAC -->
                                <mat-form-field appearance="fill">
                                    <mat-label>TAC</mat-label>
                                    <input
                                        matInput
                                        inputmode="numeric"
                                        currencyMask
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        currencyFormatter
                                        formControlName="tac"
                                        placeholder="TAC"
                                    />
                                    <mat-hint
                                        *ngIf="
                                            minibankPredefinedFees?.tacOverThirty &&
                                            minibankPredefinedFees?.tacUnderThirty
                                        "
                                    >
                                        *TAC até 30 mil:
                                        {{ minibankPredefinedFees.tacUnderThirty | currency : "BRL" }} | *TAC acima de
                                        30 mil: {{ minibankPredefinedFees.tacOverThirty | currency : "BRL" }}
                                    </mat-hint>
                                </mat-form-field>

                                <!-- Consulta de Crédito -->
                                <mat-form-field appearance="fill">
                                    <mat-label>Consulta de Crédito</mat-label>
                                    <input
                                        matInput
                                        currencyMask
                                        inputmode="numeric"
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        formControlName="creditAnalysis"
                                        placeholder="Consulta de Crédito"
                                        currencyFormatter
                                    />
                                </mat-form-field>

                                <!-- TED -->
                                <mat-form-field appearance="fill">
                                    <mat-label>TED</mat-label>
                                    <input
                                        matInput
                                        currencyMask
                                        inputmode="numeric"
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        formControlName="ted"
                                        placeholder="TED"
                                        currencyFormatter
                                    />
                                </mat-form-field>

                                <!-- PIX -->
                                <mat-form-field appearance="fill">
                                    <mat-label>PIX</mat-label>
                                    <input
                                        matInput
                                        currencyMask
                                        inputmode="numeric"
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        formControlName="pix"
                                        placeholder="pix"
                                        currencyFormatter
                                    />
                                </mat-form-field>

                                <!-- Assinatura Digital -->
                                <mat-form-field appearance="fill">
                                    <mat-label>Assinatura Digital</mat-label>
                                    <input
                                        matInput
                                        currencyMask
                                        inputmode="numeric"
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        formControlName="digitalSignatureCost"
                                        currencyFormatter
                                    />
                                </mat-form-field>

                                <!-- Tipo de serviço bancário -->
                                <mat-form-field class="md:col-span-2">
                                    <mat-label>Tipo de serviço bancário</mat-label>
                                    <mat-select
                                        #bankingServiceType
                                        formControlName="bankServiceType"
                                        (valueChange)="bankServiceTypeChange($event)"
                                        [disabled]="true"
                                    >
                                        <mat-option value="">- -</mat-option>
                                        <mat-option *ngFor="let type of bankServiceTypes" [value]="type.value">
                                            {{ type.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill" *ngIf="bankServiceTypeSelected === 'invoices'">
                                    <mat-icon
                                        svgIcon="heroicons_solid:question-mark-circle"
                                        class="text-gray-800 icon-size-5 cursor-help absolute -top-7 left-36 z-10"
                                        [matTooltip]="bankServicesValueTooltip"
                                    ></mat-icon>
                                    <mat-label>Valor total do serviço</mat-label>
                                    <input
                                        matInput
                                        currencyMask
                                        inputmode="numeric"
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        formControlName="invoices"
                                        placeholder="boletos"
                                        currencyFormatter
                                    />
                                </mat-form-field>

                                <mat-form-field appearance="fill" *ngIf="bankServiceTypeSelected === 'checkCustody'">
                                    <mat-icon
                                        svgIcon="heroicons_solid:question-mark-circle"
                                        class="text-gray-800 icon-size-5 cursor-help absolute -top-7 left-36 z-10"
                                        [matTooltip]="bankServicesValueTooltip"
                                    ></mat-icon>
                                    <mat-label class="w-fit">Valor total do serviço</mat-label>
                                    <input
                                        matInput
                                        currencyMask
                                        inputmode="numeric"
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        formControlName="checkCustody"
                                        currencyFormatter
                                    />
                                </mat-form-field>
                            </div>
                        </form>
                    </div>

                    <!-- Funding -->
                    <div class="custom-pad pt-10" *ngIf="operationData.hasFunding">
                        <h1 class="text-gray-900 font-bold text-2xl">Desembolso da operação</h1>
                        <p class="text-gray-800">
                            Além do saldo do Mini Banco, você também pode utilizar o Funding Bankme no desembolso desta
                            operação.
                        </p>
                        <div class="border rounded-2xl flex flex-col my-7">
                            <div class="py-4 flex items-center justify-around">
                                <div class="text-gray-800">
                                    <p class="text-sm">Desembolso total</p>
                                    <p class="font-bold">
                                        {{ totalDisbursement | currency : "BRL" : "symbol" : "1.2-2" }}
                                    </p>
                                </div>
                                <div class="w-px h-10 bg-gray-300"></div>
                                <div class="text-gray-800">
                                    <p class="text-sm">Saldo em conta</p>
                                    <p class="font-bold">
                                        {{ accountBalance | currency : "BRL" : "symbol" : "1.2-2" }}
                                    </p>
                                </div>
                                <div class="w-px h-10 bg-gray-300"></div>

                                <div class="text-gray-800">
                                    <p class="text-sm">Funding Bankme</p>
                                    <p class="font-bold">
                                        {{ fundingData.limit | currency : "BRL" : "symbol" : "1.2-2" }}
                                    </p>
                                </div>
                            </div>
                            <div class="bg-gray-100 w-full flex gap-2 rounded-b-lg px-4 py-2">
                                <mat-icon
                                    svgIcon="heroicons_outline:question-mark-circle"
                                    class="text-gray-800 icon-size-5 cursor-help"
                                ></mat-icon>
                                <p class="text-gray-900 text-sm">
                                    O funding bankme tem custo de {{ fundingData.rate | percent : "1.2-2" }} a.m.
                                </p>
                            </div>
                        </div>
                        <p class="text-gray-900">
                            Qual a porcentagem do Funding Bankme que gostaria de utilizar no desembolso desta operação?
                        </p>
                        <form [formGroup]="fundingForm" class="w-full py-7">
                            <div class="flex w-full gap-x-4">
                                <mat-form-field appearance="fill" class="w-full">
                                    <mat-label>Saldo em conta</mat-label>
                                    <input
                                        matInput
                                        [disabled]="true"
                                        inputmode="numeric"
                                        currencyMask
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        currencyFormatter
                                        formControlName="accountBalance"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Percentual</mat-label>
                                    <input
                                        formControlName="accountBalanceRate"
                                        matInput
                                        inputmode="numeric"
                                        currencyMask
                                        [options]="{
                                            prefix: '',
                                            suffix: ' %',
                                            thousands: '.',
                                            decimal: ',',
                                            align: 'left'
                                        }"
                                        currencyFormatter
                                    />
                                </mat-form-field>
                            </div>
                            <div class="flex w-full gap-x-4">
                                <mat-form-field appearance="fill" class="w-full">
                                    <mat-label>Funding Bankme</mat-label>
                                    <input
                                        matInput
                                        inputmode="numeric"
                                        currencyMask
                                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
                                        currencyFormatter
                                        formControlName="fundingUsed"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Percentual</mat-label>
                                    <input
                                        #taxa
                                        formControlName="fundingRate"
                                        matInput
                                        inputmode="numeric"
                                        currencyMask
                                        [options]="{
                                            prefix: '',
                                            suffix: ' %',
                                            thousands: '.',
                                            decimal: ',',
                                            align: 'left'
                                        }"
                                        currencyFormatter
                                    />
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                </div>
                <OperationSummary
                    [risk]=""
                    [operationData]="operationData"
                    [minibank]="minibankName"
                    [assignor]="assignorName"
                    [expanded]="isSummaryExpanded"
                    (expandHideEmitter)="isSummaryExpanded = !isSummaryExpanded"
                ></OperationSummary>
            </div>
        </mat-step>

        <!-- Solicitar análises para a operação -->
        <mat-step *ngIf="!isRediscountOperation">
            <div class="w-full grid md:grid-cols-2 gap-20">
                <div class="custom-pad">
                    <h1 class="text-3xl font-bold py-6">Solicitar análises para a operação</h1>
                    <h2 class="text-xl font-bold pb-6">Gostaria de solicitar alguma análise?</h2>

                    <form class="w-full grid gap-4" [formGroup]="feesForm">
                        <CheckboxCard
                            [disabled]="hasConfirmedOffRisk"
                            title="Checagem de títulos"
                            subtitle="
                                Verificaremos os títulos relacionados à operação,
                                analisando seu histórico e verificando possíveis pendências.
                            "
                            [checked]="shouldCheckPayable"
                            (changeValue)="handleChecked('shouldCheckPayable', $event); shouldCheckPayable = $event"
                        ></CheckboxCard>

                        <CheckboxCard
                            [disabled]="hasConfirmedOffRisk"
                            title="Consulta de crédito do sacado"
                            subtitle="
                                Realizaremos uma análise do histórico de crédito do Sacado, incluindo
                                informações sobre pagamentos anteriores, dívidas existentes e outros
                                fatores relevantes para avaliar sua capacidade de crédito.
                            "
                            [checked]="shouldAnalyzePayerCredit"
                            (changeValue)="
                                handleChecked('shouldAnalyzePayerCredit', $event); shouldAnalyzePayerCredit = $event
                            "
                        ></CheckboxCard>
                    </form>

                    <Button
                        class="mt-4"
                        label="Continuar sem análises ou checagens"
                        color="primary"
                        type="transparent"
                        [hideText]="false"
                        (clickEvent)="confirmOffRisk()"
                    ></Button>

                    <div class="mt-10" *ngIf="shouldAnalyzePayerCredit">
                        <h3 class="font-bold text-xl">Selecione os sacados para consulta</h3>

                        <div class="rounded-md border flex flex-col divide-y mt-4">
                            <div class="grid grid-cols-3 text-center p-3 text-sm font-semibold text-gray-600 relative">
                                <input
                                    type="checkbox"
                                    class="w-3 h-3 absolute top-3 left-2"
                                    [checked]="allPayersSelected"
                                    (change)="toggleAllPayersToAnalysis($event)"
                                />
                                <p>Nome</p>
                                <p>CPNJ</p>
                                <p class="text-right">Total em recebíveis na operação</p>
                            </div>

                            <div
                                *ngFor="let payable of payablesSum"
                                class="grid grid-cols-3 text-center text-md relative p-2"
                            >
                                <input
                                    type="checkbox"
                                    style="transform: translateY(-50%)"
                                    class="w-3 h-3 col-span-1 absolute top-1/2 left-2"
                                    [checked]="allPayersSelected"
                                    (change)="addPayableToAnalyze(payable)"
                                />
                                <p>{{ payable.sacado.razaoSocial | titlecase }}</p>
                                <p>{{ maskDocument(payable.sacado.document) }}</p>
                                <p>{{ payable.valor | currency : "BRL" }}</p>
                            </div>
                        </div>
                        <div class="mt-4">
                            <p class="text-[#71717A] font-semibold text-xs">
                                {{ payablesToAnalyze.length }} de {{ payables.length }} sacados selecionados
                            </p>
                        </div>
                    </div>
                </div>
                <OperationSummary
                    [risk]=""
                    [operationData]="operationData"
                    [minibank]="minibankName"
                    [assignor]="assignorName"
                    [expanded]="isSummaryExpanded"
                    (expandHideEmitter)="isSummaryExpanded = !isSummaryExpanded"
                ></OperationSummary>
            </div>
        </mat-step>

        <!-- Pagamentos -->
        <mat-step>
            <div class="w-full grid md:grid-cols-2 gap-20">
                <div class="custom-pad pt-6">
                    <div>
                        <h1 class="text-3xl font-bold">Pagamento da Operação</h1>
                    </div>

                    <div
                        class="mt-10 flex flex-col gap-3"
                        [formGroup]="paymentForm"
                        [ngClass]="{ hidden: bankType !== 'STANDARD' }"
                    >
                        <h2 class="text-2xl font-bold">Qual será o tipo do pagamento?</h2>

                        <mat-radio-group
                            class="flex flex-col gap-2 mx-2"
                            formControlName="paymentType"
                            [disabled]="bankType !== 'STANDARD'"
                        >
                            <mat-radio-button value="TED">TED</mat-radio-button>
                            <mat-radio-button value="PIX" [checked]="bankType !== 'STANDARD'">PIX</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="mt-10 space-y-5">
                        <h2 class="text-2xl font-bold">Selecione o beneficiário</h2>

                        <PaymentCards
                            [paymentCards]="paymentCards"
                            (changeCardSelectEvent)="selectCardBankAccount($event)"
                        ></PaymentCards>

                        <button class="flex gap-1 mt-5" (click)="openCreateBankAccountModal()">
                            <mat-icon svgIcon="heroicons_solid:plus" class="icon-size-5 text-primary-500"></mat-icon>
                            <p class="text-primary-500">Adicionar outra conta bancária</p>
                        </button>
                    </div>
                </div>

                <OperationSummary
                    [risk]=""
                    [operationData]="operationData"
                    [minibank]="minibankName"
                    [assignor]="assignorName"
                    [expanded]="isSummaryExpanded"
                    (expandHideEmitter)="isSummaryExpanded = !isSummaryExpanded"
                ></OperationSummary>
            </div>
        </mat-step>

        <!-- Observações e anexos -->
        <mat-step>
            <div class="w-full grid md:grid-cols-2 gap-20">
                <div class="custom-pad">
                    <h1 class="text-3xl font-bold py-6">Observações e anexos</h1>
                    <div class="flex w-full flex-col gt-sm:flex-row">
                        <form class="flex w-full flex-col" [formGroup]="feesForm">
                            <div class="flex flex-col w-full mb-5">
                                <div
                                    class="flex items-start gap-x-3 rounded-sm border border-slate-200 p-3 bg-neutral-50 mb-5"
                                    *ngIf="isRediscountOperation"
                                >
                                    <div class="min-w-5 min-h-5">
                                        <mat-icon
                                            svgIcon="heroicons_outline:question-mark-circle"
                                            class="text-gray-800 icon-size-5"
                                        ></mat-icon>
                                    </div>
                                    <ul class="text-black text-sm" style="list-style: inside">
                                        Os seguintes documentos devem ser enviados:
                                        <li>Títulos em formato XML ou planilha;</li>
                                        <li>Notas fiscais dos recebíveis;</li>
                                        <li>Baixa de boletos das parcelas antecipadas da operação;</li>
                                        <li>Termo de cessão entre sua financeira e o cedente;</li>
                                        <li>Comprovante de operação performada, contendo o email do sacado.</li>
                                    </ul>
                                </div>
                                <mat-label>
                                    Adicionar arquivo
                                    <span *ngIf="!isHighPerformance" class="text-gray-500">(opcional)</span>
                                </mat-label>
                                <!-- Arquivos -->
                                <ngx-dropzone
                                    (change)="onSelect($event)"
                                    class="w-full h-13 mb-4 drag-n-drop border-blue-600 border-px bg-gray-100 dark:bg-gray-600"
                                    [maxFileSize]="maxFileSize"
                                >
                                    <ngx-dropzone-label class="px-4 flex items-center justify-center">
                                        <mat-icon
                                            svgIcon="heroicons_outline:document"
                                            class="icon-size-5 text-blue-700 mr-2 dark:text-white"
                                        ></mat-icon>
                                        <p class="text-blue-700 text-base">Upload dos arquivos</p>
                                    </ngx-dropzone-label>
                                </ngx-dropzone>

                                <div *ngFor="let f of files" class="pb-4">
                                    <FileUploadCard [file]="f" (onClick)="onRemove(f)"></FileUploadCard>
                                </div>
                            </div>

                            <!-- Observação -->
                            <mat-label>
                                Gostaria de deixar uma observação nesta operação?
                                <span class="text-gray-500">(opcional)</span>
                            </mat-label>
                            <mat-form-field appearance="fill" class="fuse-mat-textarea flex-auto h-15 mb-10">
                                <textarea
                                    class="resize-none"
                                    #description
                                    formControlName="description"
                                    matInput
                                    [rows]="3"
                                ></textarea>
                            </mat-form-field>
                        </form>
                    </div>
                </div>
                <OperationSummary
                    [risk]=""
                    [operationData]="operationData"
                    [minibank]="minibankName"
                    [assignor]="assignorName"
                    [expanded]="isSummaryExpanded"
                    (expandHideEmitter)="isSummaryExpanded = !isSummaryExpanded"
                ></OperationSummary>
            </div>
        </mat-step>

        <!-- Termos de aceite funding (ng if se tem funding) -->
        <mat-step *ngIf="operationData.hasFunding && operationData.fundingDisbursement">
            <div class="w-full flex justify-center py-6">
                <div class="custom-pad w-full mx-40">
                    <h1 class="block text-xl md:text-3xl font-semibold md:font-bold pb-6">
                        <i class="ph-fill ph-info text-3xl text-blue-800 md:mt-0"></i>
                        Termo de Aceite do Funding Bankme
                    </h1>
                    <p class="text-gray-800">
                        Pelo presente instrumento, manifesto concordância e ciência quanto à contratação do Funding
                        Bankme, na forma do disposto no Contrato de Alavancagem, firmado entre as Partes, que
                        conjuntamente com estas disposições formam um todo para fins de direito, nos seguintes termos:
                    </p>
                    <h2 class="text-xl font-bold py-6">Cláusula 1ª</h2>
                    <p class="text-gray-800">
                        Para a formalização do presente Termo, a Licenciada declara que obteve de maneira clara e
                        transparente, por meio da Plataforma, a taxa pré-fixada aplicada às operações financeiras
                        realizadas utilizando-se do Funding Bankme, assim como, a data de vencimento, os percentuais, os
                        prazos, os valores e os juros de mora.
                    </p>
                    <h2 class="text-xl font-bold py-6">Cláusula 2ª</h2>
                    <p class="text-gray-800">
                        O presente termo possui vigência a partir de sua assinatura, sendo firmado em caráter
                        irrevogável irretratável, constituindo obrigações legais, válidas e vinculantes, obrigando e
                        vigorando em benefício das Partes e de seus respectivos sucessores e cessionários permitidos.
                    </p>
                    <h2 class="text-xl font-bold py-6">Cláusula 3ª</h2>
                    <p class="text-gray-800">
                        Este Termo em conjunto com o Contrato de Alavancagem e extratos constituem instrumento válido,
                        eficaz e exequível.
                    </p>
                    <h2 class="text-xl font-bold py-6">Cláusula 4ª</h2>
                    <p class="text-gray-800">
                        Ao assinar este Termo a Licenciada acima identificada pactua com BANKME SECURITIZADORA S/A ,
                        inscrita no CNPJ sob n°, 38.925.847/0001-50, a utilização do Funding Bankme, de acordo com as
                        disposições e se declara automática e expressamente vinculado às disposições previstas no
                        Contrato de Alavancagem, às quais a Licenciada declara, ao assinar este Termo, ter pleno
                        conhecimento, estar de acordo com seu teor, bem como declara para todos os fins de direitos que
                        as informações e documentos apresentados são verdadeiros.
                    </p>
                    <h2 class="text-xl font-bold py-6">Cláusula 5ª</h2>
                    <p class="text-gray-800">
                        A Licenciada declara e reconhece que as disposições constantes no presente Termo, assinado
                        digitalmente, são verdadeiras em relação aos signatários, e produzem efeitos legais, nos termos
                        do artigo 219 do Código Civil, e do artigo 408 e 784, §4º do Código de Processo Civil.
                    </p>

                    <mat-checkbox class="select-checkbox mt-10 py-5" (change)="acceptedTerms($event)">
                        Declaro que li e concordo com os termos de aceite</mat-checkbox
                    >
                </div>
            </div>
        </mat-step>
    </mat-stepper>

    <!-- PROGRESS BAR -->
    <div class="fixed bottom-0 left-0 w-full backdrop-filter backdrop-blur bg-transparent">
        <mat-progress-bar class="w-full progressBar" [mode]="'determinate'" [value]="progressValue"></mat-progress-bar>
        <div class="flex justify-center items-center gap-15 md:gap-24 lg:gap-50 xl:gap-y-80 py-5">
            <Button
                label="Anterior"
                type="icon"
                color="accent"
                [hideText]="false"
                (clickEvent)="previousProgressValue()"
            ></Button>

            <Button
                [label]="isLastStep() ? 'Finalizar' : 'Próximo'"
                type="solid"
                color="primary"
                [hideText]="false"
                (clickEvent)="isLastStep() ? saveRecebiveisCreated() : nextProgressValue()"
                [disabled]="isLoading || isNextStepDisabled"
            ></Button>
        </div>
    </div>
</div>

<WarningButton icon="receipt" class="fixed bottom-24 left-10 z-99">
    <span>
        Alguns serviços solicitados na criação da Operação podem ter custos adicionais.
        <a class="text-blue-600 font-bold" href="https://coda.io/@bankme/tabela-de-custos-e-tarifas" target="_blank">
            Clique aqui
        </a>
        para conferir a tabela.
    </span>
</WarningButton>
