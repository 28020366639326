const messages = {
    ALERT_FILL_ASSIGNOR_FIELD: "O campo CEDENTE deve ser preenchido",
    ALERT_ALL_FIELDS_REQUIRED: "Preencha todos os campos obrigatórios para avançar a próxima etapa!",
    SUCCESS_OPERATATION_CREATED: "Operação criada com sucesso!",
    ALERT_OPERATION_SHOULD_CONTAIN_PAYABLE: "A Operação deve conter Recebíveis!",
    ALERT_PAYABLES_MUST_HAVE_DUE_DATE: "Todos os Recebiveis devem ter data de vencimento definida!",
    ALERT_SOME_FIELDS_REQUIRED: "Alguns campos não foram preenchidos corretamente",
    TOOLTIP_BANK_SERVICES_VALUE:
        "Insira o valor total dos serviços bancários, multiplicando a quantidade de cheques ou boletos pelo valor unitário de cada um.",
    ALERT_SELECT_AT_LEAST_ONE_CHECKBOX: "Selecione ao menos uma das opções para avançar a próxima etapa!",
    ALERT_INSUFFICIENT_FUNDS: "Saldo insuficiente para realizar a operação!",
    ALERT_NEGATIVE_PROFITABILITY: "A rentabilidade não pode ser negativa!",
    ALERT_FUNDING_MORE_THAN_LIMIT: "O valor utilizado de funding não pode ser maior que o limite disponível!",
    ALERT_FUNDING_RATE_MORE_THAN_100_PERCENT: "A taxa de funding não pode ser maior que 100%!",
    ALERT_ACCEPT_TERMS: "Para operações usando funding os termos devem ser aceitos!",
    ALERT_OPERATION_MUST_CONTAIN_PAYABLE: "O valor da recompra não pode ser maior que o valor dos títulos da operação!",
    ALERT_INVALID_FUNDING: "Valores inválidos para utilização de funding!",
    ALERT_OPERATION_WITHOUT_FILES: "É necessário anexar os documentos da operação",
};

export default messages;
