export const environment = {
    production: false,
    environment: "demo",
    vars: {
        apis: {
            crm: "https://api-bankme-demo.bankme.tech",
            oracle: "https://api-oracle-demo.bankme.tech",
            credito: "https://credito.bankme.tech",
            bff: "https://api-oracle-demo.bankme.tech/bff-front-bankme",
            monitor: "https://worker-monitor-demo.bankme.tech/",
        },
        datadog: {
            clientToken: "pube0b4d3eb039cc3f49a8e6aab60d17f9e",
            applicationId: "e2a61d17-127e-4647-a485-d65d5eacfc5e",
        },
        amplitude: {
            apiKey: "b14ca311720108b69a988f69c0045160",
        },
        next: "https://demo.bankme.app",
        growthbook: {
            clientKey: "sdk-pBR9C1Ns2G7jH6K",
        },
        email: "b543abf53743aecc4e0a5ae470286291",
        hj: "2717720",
        whitelist: ["https://viacep.com.br"],
        ga: "G-T9VEMG58KV",
    },
};
