export function calculateCompoundInterest({
    value,
    percentage,
    days,
}: {
    value: number;
    percentage: number;
    days: number;
}) {
    const dailyTax = Math.pow(1 + percentage, 1 / 30) - 1;
    const diff = value * Math.pow(1 + dailyTax, days) - value;

    return Number(diff.toFixed(2));
}

export function calculateSimpleInterest({
    value,
    percentage,
    days,
}: {
    value: number;
    percentage: number;
    days: number;
}) {
    const months = days / 30;
    const simpleInterest = value * percentage * months;
    return Number(simpleInterest.toFixed(2));
}